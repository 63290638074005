exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-[ids]-js": () => import("./../../../src/pages/blog/[ids].js" /* webpackChunkName: "component---src-pages-blog-[ids]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-and-condition-js": () => import("./../../../src/pages/legal/terms-and-condition.js" /* webpackChunkName: "component---src-pages-legal-terms-and-condition-js" */),
  "component---src-pages-response-js": () => import("./../../../src/pages/response.js" /* webpackChunkName: "component---src-pages-response-js" */),
  "component---src-pages-single-index-js": () => import("./../../../src/pages/Single/index.js" /* webpackChunkName: "component---src-pages-single-index-js" */)
}

